import styled from 'styled-components';
import { Options } from '../../SearchAutoComplete/SearchAutoComplete.style';

export const Container = styled.div`
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.textColor};
  margin: ${(props) => props.margin};
  font-size: 1.5vw;
  font-family: 'Lora', serif;
  padding: 10vw;
  text-align: center;

  ${Options} {
    margin-left: -5%;
    max-height: 400px;
  }
  span {
    font-size: 2em;
    padding: 0 3vw 0 3vw;
    font-family: 'Lora', serif;
    font-weight: normal;

    p {
      font-size: 2vw;
      line-height: 42px;

      @media (max-width: 700px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
`;
