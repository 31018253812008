import styled from 'styled-components';
import { Theme } from '../../../layout/Basic';

export const Img = styled.div`
  width: auto;
  height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  @media (max-width: 1100px) {
    height: 60vh;
  }
  @media (max-width: 900px) {
    height: 40vh;
  }
  @media (max-width: 700px) {
    width: 100vw;
    height: 30vh;
  }
`;
export const Overlay = styled.div`
  text-align: center;
  color: white;
  position: absolute;
  width: 60%;
  box-shadow: 0 0 8px 8px rgba(32, 32, 32, 0.16);
  border-radius: 15px;
  background: radial-gradient(
    rgba(46, 46, 46, 0.49) 0%,
    rgba(46, 46, 46, 0.06) 100%
  );
  @media (max-width: 500px) {
    width: 100%;
  }
  h1 {
    font-size: 4vw;

    @media (max-width: 500px) {
      font-size: 25px;
    }
  }
`;

export const HeroWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  @media (max-width: 800px) {
    padding-bottom: 50px;
  }
`;

export const Left = styled.div`
  width: 50%;
  background-color: ${Theme.color.secondary};
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  text-align: start;
  padding: 8%;

  h1 {
    font-size: 54px;
    margin: 0;
    font-family: 'Lora', serif;
  }

  h2 {
    font-size: 36px;
    font-family: 'Lora', serif;
    margin: 0;
    font-style: italic;
  }

  p {
    font-size: 13px;
    font-weight: 500;
    color: ${Theme.color.primaryDark};
    text-transform: uppercase;
  }

  @media (max-width: 800px) {
    min-width: 100%;
  }
`;

export const Right = styled.div`
  background-color: black;
  width: 50%;
  display: flex;
  height: auto;
  position: relative;
  @media (max-width: 800px) {
    width: 100%;
    height: 400px;
  }
`;

export const RightOverlay = styled.div`
  color: white;
  font-size: 14px;
  align-self: end;
  width: 100%;
  display: flex;
  justify-content: start;
  padding: 20px 100px 5px 100px;
  position: absolute;

  @media (max-width: 1200px) {
    padding: 10px;
  }

  @media (max-width: 800px) {
    position: absolute;
    bottom: -50px;
    color: black;
  }
`;
