import { useEffect, useState } from 'react';

export const useGetLocalStorage = (key) => {
  const [storageItem, setStorageItem] = useState('');
  useEffect(() => {
    setStorageItem(localStorage.getItem(key));
  }, [key]);

  return storageItem;
};

export const useSetLocalStorage = (key, value) => {
  useEffect(() => {
    value ? localStorage.setItem(key, value) : localStorage.setItem(key, '');
  }, [key, value]);
};

export const useRemoveLocalStorage = (key) => {
  useEffect(() => {
    localStorage.removeItem(key);
  }, [key]);
};
