import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useTranslation } from 'next-i18next';
import BasicLayout, { Theme } from '../layout/Basic';
import Link from 'next/link';
import Head from 'next/head';
import { Hero } from '../src/components/Hero/Hero';
import img from '../public/images/hero-img.png';
import { Button } from '../src/components/Common/Button/Button';
import ArrowRightIcon from '../public/icons/ArrowRightIcon';
import { Banner } from '../src/components/Common/Banner/Banner';
import { SearchBar } from '../src/components/SearchBar/SearchBar';
import { FrontPageTiles } from '../src/components/FrontPageTiles/FrontPageTiles';
import staticData from '../public/StaticData/frontpage_data.json';
import { useRemoveLocalStorage } from '../src/hooks/useLocalStorage';

export default function Home({ staticData }) {
  useRemoveLocalStorage('search');
  useRemoveLocalStorage('tags');
  useRemoveLocalStorage('filters');
  const { t } = useTranslation('common');

  return (
    <BasicLayout>
      <Head>
        <title>{t('digital florentine codex')}</title>
        <meta
          property="og:title"
          content="Digital Florentine Codex"
          key="title"
        />
        <meta
          name="description"
          content="The Getty Research Institute provides global access to the Florentine Codex, considered the most important manuscript of early colonial Mexico"
          key="desc"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://florentinecodex.getty.edu" />
        <meta
          property="og:image"
          content="https://florentinecodex.getty.edu/_next/static/media/hero-img.2b265127.png"
        />
      </Head>

      <Hero img={img}>
        <h1>{t('hero_text')}</h1>

        <Link passHref href={`/resources`}>
          <Button>
            <ArrowRightIcon />
            {t('cta')}
          </Button>
        </Link>
      </Hero>
      <Banner textColor="black" bgColor={Theme.color.secondary}>
        <p> {t('secondary_banner')} </p>
      </Banner>

      <FrontPageTiles data={staticData} />

      <Banner bgColor={Theme.color.primary} textColor="white">
        <h2>{t('search_banner')}</h2>
        <SearchBar width="888px" />
      </Banner>
    </BasicLayout>
  );
}
export const getStaticProps = async ({ locale }) => {
  return {
    props: {
      staticData,
      ...(await serverSideTranslations(locale, ['common'])),
    },
  };
};
