import styled from 'styled-components';
import { Theme } from '../../../layout/Basic';

export const Brick = styled.button`
  background-color: ${Theme.color.primary};
  color: white;
  border-radius: 15px;
  width: 211px;
  padding: 20px;
  height: 60px;
  font-size: 18px;
  border: none;
  font-family: 'Lora', serif;
  display: block;

  :hover {
    cursor: pointer;
    color: ${Theme.color.primary};
    background-color: ${Theme.color.primaryLight};
  }
`;
export const TileOverlay = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 15px;
  width: 210px;
  height: 210px;
  z-index: 2;
`;

export const TileLink = styled.button`
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  background-color: transparent;
  border: none;
  color: white;

  svg {
    font-size: 20px;
    margin: 2px;
  }

  :hover {
    text-decoration: underline;
    cursor: pointer;
  }

  :focus {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const Tile = styled.div`
  color: ${Theme.color.black1};
  width: 211px;
  font-family: 'Lora', serif;
  font-weight: normal;
  font-size: 18px;
  position: relative;
  margin: 20px;
  img {
    filter: blur(2px);
  }

  small {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 15px;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
  }

  &:not(:hover, :focus, :active, :focus-visible, :focus-within) {
    ${TileOverlay} {
      visibility: hidden;
    }
    img {
      filter: blur(0);
    }
  }
`;

export const CardTile = styled.div`
  background-color: white;
  width: 437px;
  height: 306px;
  border-radius: 15px;
  box-shadow: ${Theme.shadow};

  :hover {
    box-shadow: ${Theme.shadow2};
    cursor: pointer;

    p {
      text-decoration: underline;
    }
  }

  p {
    font-size: 20px;
    font-family: 'Lora', serif;
    padding-left: 15px;
    font-weight: 400;
    margin-top: 0;
  }
`;
