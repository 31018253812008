import { Brick, Tile, CardTile, TileLink, TileOverlay } from './Tiles.style.js';
import ArrowRightIcon from '../../../public/icons/ArrowRightIcon';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
export const Tiles = ({
  type,
  title,
  subTitle,
  image,
  ariaLabel,
  alt,
  links,
  onClick,
}) => {
  const { t } = useTranslation('common');

  return (
    <>
      {type === 'brick' && (
        <Brick
          onClick={() => {
            onClick(links[0]);
          }}
          aria-label={ariaLabel}
        >
          {title}
        </Brick>
      )}

      {type === 'tile' && (
        <Tile tabIndex={0}>
          <TileOverlay>
            {links.map((link, i) => {
              return (
                <TileLink
                  onClick={() => {
                    onClick(link);
                  }}
                  aria-label={ariaLabel}
                  key={i}
                >
                  <ArrowRightIcon fill={'white'} />
                  {link.count}{' '}
                  {link.type === 'image_search'
                    ? t('images')
                    : link.type === 'codex_folio'
                    ? t('go to book')
                    : t('texts')}
                </TileLink>
              );
            })}
          </TileOverlay>
          <Image
            width={210}
            height={210}
            src={image}
            alt={alt}
            objectFit={'cover'}
            style={{ borderRadius: 15, zIndex: 0 }}
          />
          <small>{subTitle}</small> <br />
          {title}
        </Tile>
      )}

      {type === 'card' && (
        <CardTile
          onClick={(link) => {
            onClick(link);
          }}
          aria-label={ariaLabel}
        >
          <Image fill src={image} alt={alt} objectFit={'contain'} />
          <p>{title}</p>
        </CardTile>
      )}
    </>
  );
};
