import styled from 'styled-components';
import { Theme } from '../../../layout/Basic';

export const TileWrapper = styled.div`
  padding: 50px;

  @media (max-width: 600px) {
    padding: 20px;
  }
`;

export const SectionTitle = styled.h3`
  font-size: 36px;
  font-family: 'Lora', serif;
  margin: 30px 0;
  //text-transform: capitalize;

  //mobile
  @media (max-width: 600px) {
    text-align: center;
  }
`;

export const TileSection = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(6, 220px);
  grid-template-rows: repeat(4, auto);
  justify-content: space-between;
  grid-row-gap: 10px;

  &[data-is-bricks='true'] {
    grid-template-columns: repeat(auto-fill, 220px);
    grid-template-rows: repeat(4, auto);
  }
  //tablet
  @media (max-width: 850px) {
    grid-template-columns: repeat(auto-fill, 240px);
    justify-content: space-evenly;
  }

  //mobile
  @media (max-width: 500px) {
    grid-template-columns: repeat(auto-fill, auto);
    justify-content: center;
  }

  //extra large
  @media (min-width: 1600px) {
    grid-template-columns: repeat(6, auto);
    grid-template-rows: repeat(2, auto);
  }
`;
