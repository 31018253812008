import {
  Img,
  Overlay,
  Right,
  Left,
  HeroWrapper,
  RightOverlay,
} from './Hero.style.js';
import Image from 'next/image';

export const Hero = ({ img, alt, children, split, ImgText }) => (
  <>
    {!split ? (
      <Img>
        <Image
          priority={true}
          role="presentation"
          src={img}
          alt={alt}
          width={0}
          height={0}
          layout={'fill'}
          objectFit={'cover'}
          objectPosition={'center 20%'}
        />
        <Overlay>{children}</Overlay>
      </Img>
    ) : (
      <HeroWrapper>
        <Left>{children}</Left>
        <Right>
          <Image
            layout="fill"
            objectFit="contain"
            role="presentation"
            width={0}
            height={0}
            src={img}
            alt={alt}
          />
          <RightOverlay>{ImgText}</RightOverlay>
        </Right>
      </HeroWrapper>
    )}
  </>
);
